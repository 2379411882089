import http from "../utility/http";

const Search = async (
  category,
  query,
  limit,
  page = 1,
  sortBy = "id",
  sortOrder = "asc",
  withTotal = false
) => {
  if (withTotal) {
    return await http.getBackgroundRequest(
      `/v1/search/${category}?fromPortal=true&q=${query || ""}&limit=${
        limit || 50
      }&page=${page || 1}&sortBy=${sortBy || "id"}&sortOrder=${
        sortOrder || "asc"
      }`
    );
  }
  return await http.getBackgroundRequest(
    `/v1/search/${category}?fromPortal=true&q=${query || ""}&limit=${
      limit || 50
    }`
  );
};

const api = {
  Search,
};

export default api;
