import http from "../utility/http";

const Create = async (transferRequest) => {
  return await http.post("/v1/transfer/transfer-in-request", transferRequest);
};

const Update = async (transferRequest) => {
  return await http.put(`/v1/transfer/transfer-in-request`, transferRequest);
};

const Delete = async (id) => {
  return await http.delete(`/v1/transfer/transfer-in-request/${id}`, {});
};

const GetAll = async (keyword, page = 1, limit = 50, sort) => {
  //add pagination + sorting + filteration
  let sortQuery = "";
  if (sort) {
    sortQuery = `&sortBy=${sort?.sortBy}&sortOrder=${sort?.sortOrder}`;
  }
  return await http.get(
    `/v1/transfer/transfer-in-request/list?q=${keyword}&page=${page}&limit=${limit}${sortQuery}`
  );
};

const GetById = async (id) => {
  return await http.get(`/v1/transfer/transfer-in-request/${id}`);
};

const Accept = async (transferRequestAction) => {
  return await http.post(
    "/v1/transfer/transfer-in-request/accept",
    transferRequestAction
  );
};

const Reject = async (transferRequestAction) => {
  return await http.post(
    "/v1/transfer/transfer-in-request/reject",
    transferRequestAction
  );
};

const Cancel = async (transferRequestAction) => {
  return await http.post(
    "/v1/transfer/transfer-in-request/cancel",
    transferRequestAction
  );
};

const api = {
  GetById,
  GetAll,
  Create,
  Update,
  Delete,
  Accept,
  Reject,
  Cancel,
};

export default api;
