export const ROUTES = {
  ROOT_ROUTE: "/",
  DASHBOARD_ROUTE: "/dashboard",
  LOGIN_ROUTE: "/login",
  FORGOTPASSWORD_ROUTE: "/forgot-password",
  RESETPASSWORD_ROUTE: "/reset-password",
  ACCEPTINVITE_ROUTE: "/accept-invite",
  SIGNUP_ROUTE: "/signup",
  LOGIN_WITH_CODE_ROUTE: "/login-with-code",
  LOGIN_WITH_TOKEN_ROUTE: "/login-with-token",
  USER_PROFILE_ROUTE: "/user-profile",
  TRANSFER_IN_REQUEST_ROUTE: "/transfer-in-requests",
  TRANSFER_OUT_REQUEST_ROUTE: "/transfer-out-requests",
  USER_ROUTE: "/users",
  NOT_FOUND_ROUTE: "/not-found",
  CHOOSE_PHARMACY_ACCOUNT_ROUTE: "/choose-pharmacy-account",
  PATIENT_ROUTE: "/patients",
  PRESCRIBER_ROUTE: "/prescribers",
  DRUG_ROUTE: "/drugs",
  PHARMACY_ROUTE: "/pharmacies",
  REPORTS_ROUTE: "/reports",
  TRANSFERS_ROUTE: "/transfers",
  MESSAGE_ANOTHER_PHARMACY_ROUTE: "/message-from-another-pharmacy",
  PATIENTS_REQUEST_ROUTE: "/patient-request",
  PHARMACY_BRANCH_REPORTS_ROUTE: "/pharmacy-branch-reports",
  DRUG_REPORTS_ROUTE: "/drug-reports",
  REPORT_ROUTE: "/report",
  REFERAL_ROUTE: "/referals",
};
