import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import Loading from "../../components/Loading";
import { ROUTES } from "../../constants/routes.constants";
import images from "../../assets/images";
import SideMenuItem from "../../components/SideMenuItem";
import HeaderNotification from "../../components/HeaderNotification";
import userService from "../../services/user.service";
import lookupService from "../../services/lookup.service";
import notificationService from "../../services/notification.service";
import { lookupSlice } from "../../redux/slices/lookup.slice";
import { userSlice } from "../../redux/slices/user.slice";
import Emitter from "../../utility/emitter";
import UpdateGuestInfoModal from "../../components/UpdateGuestInfoModal";
import helpers from "../../utility/helpers";
import http from "../../utility/http";
import { useRecoilState } from "recoil";
import { patinetRequestState, notificationsState } from "../../atoms";
import { firstDoseState } from "../../atoms/firstDoes";
const ProtectedLayout = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [patinetRequest, setPatinetRequest] =
    useRecoilState(patinetRequestState);
  useEffect(() => {
    const getData = async () => {
      const total = await http.post("/v1/patient-request-transfer-pagination", {
        limit: 1000,
        filter: {
          status: "pending",
        },
      });
      setPatinetRequest({
        ...patinetRequest,
        total: total?.data?.totalPending,
      });
    };
    getData();
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.currentUser);

  const [pharmacyBranches, setPharmacyBranches] = useState([]);
  const getUserPharmacyBranches = async () => {
    const userPharmacyBranches = await userService.GetPharmacyBranches();
    setPharmacyBranches(userPharmacyBranches.data);
  };

  const handleSetBranchClick = async (pharmacyBranchId) => {
    const result = await userService.SetPharmacyBranch(pharmacyBranchId);
    if (result.error === null) {
      dispatch(userSlice.actions.login(result.data));
      window.location.reload();
    }
  };
  useEffect(() => {
    if (currentUser?.user) {
      getUserPharmacyBranches();
    }
  }, []);

  const [pharmacyBranchFullAddress, setPharmacyBranchFullAddress] =
    useState("");
  // const [notifications, setNotifications] = useState([]);
  const [showUpdateGuestInfoModal, setShowUpdateGuestInfoModal] =
    useState(false);
  const [isLookupsLoaded, setIsLookupsLoaded] = useState(false);
  const location = useLocation();

  const [firstDose, setFirstDose] = useRecoilState(firstDoseState);

  const sideMenuItems = {
    dashboard: {
      text: "Dashboard",
      route: ROUTES.DASHBOARD_ROUTE,
      icon: "fa-solid fa-gauge-high",
    },

    pharmacies: {
      text: "Pharmacies",
      route: ROUTES.PHARMACY_ROUTE,
      icon: "fas fa-clinic-medical",
      badge: 0,
    },
    transfers: {
      text: "Transfers",
      route: ROUTES.TRANSFERS_ROUTE,
      icon: "fa-solid fa-download",
      badge: 0,
    },

    drugs: {
      text: "Drugs",
      route: ROUTES.DRUG_ROUTE,
      icon: "fas fa-capsules",
      badge: 0,
    },
    patients: {
      text: "Patients",
      route: ROUTES.PATIENT_ROUTE,
      icon: "fas fa-user-injured",
      badge: 0,
    },

    prescribers: {
      text: "Prescribers",
      route: ROUTES.PRESCRIBER_ROUTE,
      icon: "fas fa-user-md",
      badge: 0,
    },

    transferIn: {
      text: "Transfer In",
      route: ROUTES.TRANSFER_IN_REQUEST_ROUTE,
      icon: "fa-solid fa-download",
      badge: 0,
    },

    transferOut: {
      text: "Transfer Out",
      route: ROUTES.TRANSFER_OUT_REQUEST_ROUTE,
      icon: "fa-solid fa-upload",
      badge: 0,
    },

    MessageAnotherPharmacy: {
      text: "Messaging",
      route: ROUTES.MESSAGE_ANOTHER_PHARMACY_ROUTE,
      icon: "fa-solid fa-message",
      badge: 0,
    },

    patinetRequest: {
      text: "Patients Request",
      route: ROUTES.PATIENTS_REQUEST_ROUTE,
      icon: "fa-solid fa-gauge-high",
      badge: 0,
    },
    users: {
      text: "Users",
      route: ROUTES.USER_ROUTE,
      icon: "fas fa-users",
      badge: 0,
    },

    reports: {
      text: "Reports",
      route: ROUTES.REPORT_ROUTE,
      //icon: "fa-solid fa-square-poll-vertical",
      icon: "fa-solid fa-file-invoice",
      badge: 0,
    },
    referals: {
      text: "Referals",
      route: ROUTES.REFERAL_ROUTE,
      icon: "fa-solid fa-handshake-simple",
      badge: 0,
    },
  };
  const sideMenuToggleClick = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const handleProfileClick = async () => {
    navigate(ROUTES.USER_PROFILE_ROUTE);
  };

  const handleManageUsersClick = () => {
    navigate(ROUTES.USER_ROUTE);
  };

  const handleLogoutClick = async () => {
    await userService.Logout();
    dispatch(userSlice.actions.logout());
    navigate(ROUTES.LOGIN_ROUTE);
  };

  const getAllLookups = async () => {
    const lookups = await lookupService.GetAll();
    if (lookups.error === null) {
      // console.log("lookups.data", lookups.data);
      await dispatch(lookupSlice.actions.getAll(lookups.data));
      setIsLookupsLoaded(true);
    }
  };
  useEffect(() => {
    const userData = currentUser && currentUser.user;
    if (!userData) {
      navigate(ROUTES.LOGIN_ROUTE);
      return;
    }
    console.log("userData", userData);
    const index = firstDose.pharmacies.findIndex(
      (e) => e.PharmacyId === userData.pharmacyId
    );
    console.log("index", index);
    if (index !== -1) {
      setFirstDose((prev) => {
        const newState = { ...prev, isInList: true };
        return newState;
      });
    }
    let socket;
    setPharmacyBranchFullAddress(
      helpers.getPharmacyFormattedAddress(
        userData.pharmacyBranchAddress,
        userData.pharmacyBranchCityName,
        userData.pharmacyBranchStateName
      )
    );

    if (
      (userData.roleId === 2 ||
        userData.roleId === 3 ||
        userData.roleId === 4) &&
      !userData.pharmacyBranchId
    ) {
      navigate(ROUTES.CHOOSE_PHARMACY_ACCOUNT_ROUTE);
    } else {
      getAllLookups();

      Emitter.on("SHOW_GUEST_REGISTERATION_MODAL", () => {
        setShowUpdateGuestInfoModal(true);
      });
      Emitter.on("update-notifications", () => {
        const pageNumber = 1;
        notificationService.GetAll(pageNumber).then((result) => {
          if (result.error === null) {
            setNotifications(result.data);
          }
        });
      });

      Emitter.on("REDIRECT_TO_CHOOSE_PHARMACY", () => {
        navigate(ROUTES.CHOOSE_PHARMACY_ACCOUNT_ROUTE);
      });

      Emitter.emit("update-notifications");
    }
    return () => {
      Emitter?.off("update-notifications");

      Emitter?.off("SHOW_GUEST_REGISTERATION_MODAL");
      Emitter?.off("REDIRECT_TO_CHOOSE_PHARMACY");

      socket?.off("update-notifications");
      socket?.off("update-transfer-request-list");
      socket?.off("update-pharmacy-branch-user-list");
    };
  }, []);

  return (
    isLookupsLoaded &&
    (!currentUser || !currentUser.user ? (
      <Navigate to={{ pathname: ROUTES.LOGIN_ROUTE }} state={location} />
    ) : (currentUser.user.roleId === 2 ||
      currentUser.user.roleId === 3 ||
      currentUser.user.roleId === 4) &&
      !currentUser.user.pharmacyBranchId ? (
      <Navigate
        to={{ pathname: ROUTES.CHOOSE_PHARMACY_ACCOUNT_ROUTE }}
        state={location}
      />
    ) : (
      <div id="page-container">
        <div id="sidebar-container">
          <div id="sidebar-logo-container" style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            textAlign: "center",
            padding: "10px",
          }}>
            Software B
          </div>

          <div id="sidebar-scroll-container">
            <ul id="sidebar-menu" className="">
              {currentUser.user.roleId === 7 ? (
                <>
                  <SideMenuItem
                    {...sideMenuItems.transferIn}
                    badge={notifications?.TotalNewTransferIns}
                  ></SideMenuItem>
                  <SideMenuItem
                    {...sideMenuItems.transferOut}
                    badge={notifications?.TotalNewTransferOuts}
                  ></SideMenuItem>
                </>
              ) : (
                <></>
              )}
              {currentUser.user.roleId === 1 ? (
                <>
                  <SideMenuItem {...sideMenuItems.dashboard}></SideMenuItem>
                  <SideMenuItem {...sideMenuItems.pharmacies}></SideMenuItem>
                  <SideMenuItem {...sideMenuItems.users}></SideMenuItem>
                  <SideMenuItem {...sideMenuItems.transferIn}></SideMenuItem>
                  <SideMenuItem {...sideMenuItems.transferOut}></SideMenuItem>
                  <SideMenuItem {...sideMenuItems.drugs}></SideMenuItem>
                  <SideMenuItem {...sideMenuItems.referals}></SideMenuItem>
                  {!firstDose.isInList && (
                    <SideMenuItem
                      {...sideMenuItems.patinetRequest}
                    ></SideMenuItem>
                  )}
                </>
              ) : null}

              {currentUser.user.roleId === 2 ||
                currentUser.user.roleId === 3 ? (
                <>
                  <SideMenuItem {...sideMenuItems.dashboard}></SideMenuItem>
                  <SideMenuItem
                    {...sideMenuItems.transferIn}
                    badge={notifications?.TotalNewTransferIns}
                  ></SideMenuItem>
                  <SideMenuItem
                    {...sideMenuItems.transferOut}
                    badge={notifications?.TotalNewTransferOuts}
                  ></SideMenuItem>
                  {!firstDose.isInList && (
                    <SideMenuItem
                      {...sideMenuItems.patinetRequest}
                      badge={patinetRequest.total}
                    ></SideMenuItem>
                  )}
                  <SideMenuItem
                    {...sideMenuItems.MessageAnotherPharmacy}
                    badge={notifications?.TotalNewMessages}
                  ></SideMenuItem>
                  <SideMenuItem
                    {...sideMenuItems.users}
                    badge={notifications?.TotalNewUsers}
                  ></SideMenuItem>
                  <SideMenuItem {...sideMenuItems.reports}></SideMenuItem>
                </>
              ) : null}
            </ul>
          </div>
        </div>

        <div id="main-container">
          <div id="header-container">
            <div id="sidebar-toggle" onClick={sideMenuToggleClick}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>

            <span id="page-title-text"></span>

            <Dropdown id="header-user-container">
              <Dropdown.Toggle id="header-user">
                <img
                  id="header-user-avatar"
                  alt=""
                  src={
                    currentUser?.user?.avatarImageUrl || images.DefaultAvatar
                  }
                />
                <span className="pe-3">
                  Hello,<strong>&nbsp;{currentUser?.user?.firstname}</strong>
                  {currentUser?.user?.pharmacyBranchName && (
                    <span
                      title={`${currentUser?.user?.pharmacyBranchName} (${pharmacyBranchFullAddress})`}
                    >
                      {`${currentUser?.user?.pharmacyBranchName} (${pharmacyBranchFullAddress})`}
                    </span>
                  )}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleProfileClick}>
                  <i className="fas fa-user-circle"></i>
                  <span className="ms-2">Profile</span>
                </Dropdown.Item>

                {currentUser?.user?.roleId === 1 ||
                  currentUser?.user?.roleId === 2 ? (
                  <Dropdown.Item onClick={handleManageUsersClick}>
                    <i className="fas fa-users"></i>
                    <span className="ms-2">Manage Users</span>
                  </Dropdown.Item>
                ) : null}

                <Dropdown.Item onClick={handleLogoutClick}>
                  <i className="text-danger fas fa-sign-out"></i>
                  <span className="ms-2">Logout</span>
                </Dropdown.Item>

                <Dropdown.Item>
                  <div className="d-flex mb-4 flex-column pharmacy-list">
                    {pharmacyBranches &&
                      pharmacyBranches.length !== 0 &&
                      pharmacyBranches?.map((pharmacyBranch, index) => (
                        <div
                          key={index}
                          onClick={() =>
                            handleSetBranchClick(pharmacyBranch.Id)
                          }
                        >
                          <h4>{pharmacyBranch.Name}</h4>
                          <h6>{`${pharmacyBranch.Address}${pharmacyBranch.CityName
                              ? `${pharmacyBranch.Address ? ", " : ""}${pharmacyBranch.CityName
                              }`
                              : ""
                            }${pharmacyBranch.StateName
                              ? `${pharmacyBranch.Address ||
                                pharmacyBranch.CityName
                                ? ", "
                                : ""
                              }${pharmacyBranch.StateName}`
                              : ""
                            }`}</h6>
                          <i className="fa-solid fa-chevron-right"></i>
                        </div>
                      ))}
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {currentUser?.user?.roleId !== 1 && (
              <HeaderNotification
                notifications={notifications}
              ></HeaderNotification>
            )}
          </div>

          <div id="body-container" className="p-5">
            <Outlet />
            <Loading></Loading>
          </div>
        </div>

        <UpdateGuestInfoModal
          show={showUpdateGuestInfoModal}
          onHide={() => {
            setShowUpdateGuestInfoModal(false);
          }}
        />
      </div>
    ))
  );
};

export default ProtectedLayout;
