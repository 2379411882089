import React, { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { components } from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import moment from "moment";
import { debounce } from "lodash";
import EditPatientModal from "../EditPatientModal";
import SearchService from "../../services/search.service";
import Accordion from "react-bootstrap/Accordion";
import MessageAnotherPharmacyService from "../../services/MessageAnotherPharmacy.service";

import "./index.scss";
const MessageAnotherPharmacyModal = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [patientModalShow, setPatientModalShow] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showMoreFields, setShowMoreFields] = useState(props.requesttype === 1);
  const [other, setOther] = useState("");
  const [selectedMessageRequestType, setSelectedMessageRequestType] =
    useState(null); // 1=Reverse Claim  ,  2= check if drug exist in stock ,3= other

  const [toPharmacy, setToPharmacy] = useState(null);
  const [patient, setPatient] = useState(null);
  const [prescribedDrug, setPrescribedDrug] = useState(null);

  const loadOptions = debounce((category, inputValue, callback) => {
    SearchService.Search(category, inputValue).then((data) =>
      callback(data?.data)
    );
  }, 300);

  const formatOptionLabel = ({ label, id }, { inputValue }) => {
    if (!inputValue || id === inputValue) {
      return label;
    }
    let highlighted;
    if (label.toLowerCase().includes(inputValue.toLowerCase())) {
      highlighted = label
        .toLowerCase()
        .replaceAll(inputValue.toLowerCase(), `<b>${inputValue}</b>`);

      return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
    }
    return label;
  };

  const getCustomClasses = (fieldName) => {
    return !showMoreFields && !fieldsSchema[fieldName].isRequired
      ? "d-none"
      : `form-group ${!!formErrors[fieldName] ? " invalid" : ""}${
          fieldsSchema[fieldName].cssClass
            ? fieldsSchema[fieldName].cssClass
            : ""
        }${!showMoreFields ? " w-100" : ""}`;
  };

  const handleSelect_section = (eventKey) => {
    setSelectedMessageRequestType(eventKey);
    clearForm();
  };

  const clearForm = () => {
    setPatient(null);
    setPrescribedDrug(null);
  };

  const isItemSelected = (eventKey) => {
    return selectedMessageRequestType === eventKey;
  };

  const patientMenu = (props) => {
    return (
      <div className="position-relative">
        <div className="position-absolute container text-center react-select-menu-header">
          <div className="row">
            <div className="col">Name</div>
            <div className="col">Mobile</div>
            <div className="col">DOB</div>
          </div>
        </div>
        <components.Menu {...props} className="pt-4">
          {props.children}
        </components.Menu>
      </div>
    );
  };

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        {props.data.label}
      </components.SingleValue>
    );
  };

  const addMessageToJson = () => {
    const message = 
          selectedMessageRequestType === "1" ? "Reverse Claim" :
          selectedMessageRequestType === "2" ? "Check If Medicine In Stock" :
          other;
    const dateTime = moment().format();
    const newMessage = {
      "fromPharmacy": currentUser.user.pharmacyBranchId,
      "fromPharmacyName": currentUser.user.pharmacyName,
      "datetime": dateTime,
      "pharmasticName": currentUser.user.firstname +" "+ currentUser.user.lastname  ,
      "message": message,
      "isRead": false
    };
    const updatedMessages = [newMessage];
    return updatedMessages;
};

  const fieldsSchema = {
    toPharmacyId: { isRequired: true },
    toPharmacy: { isRequired: true },
    other: { isRequired: true },
    prescribedDrugId: { isRequired: true },
    prescribedDrug: { isRequired: true },
    selectedMessageRequestType: { isRequired: true },
    prescribedDrugId_stock: { isRequired: true },
    patient: { isRequired: true },
    patientid: { isRequired: true },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      toPharmacyId: "",
      other: "",
      selectedMessageRequestType: "",
      prescribedDrugId: "",
      prescribedDrugId_stock: "",
      patientid: "",
    },
    validationSchema: (values) => {
      return Yup.object({
        toPharmacyId: Yup.string().required("Field is required"),
        prescribedDrugId:
          selectedMessageRequestType === "1"
            ? Yup.string().required("Field is required")
            : Yup.string(),
         patientid:
          selectedMessageRequestType === "1"
            ? Yup.string().required("Field is required")
            : Yup.string(),
        prescribedDrugId_stock:
          selectedMessageRequestType === "2"
            ? Yup.string().required("Field is required")
            : Yup.string(),
        other:
          selectedMessageRequestType === "3"
            ? Yup.string().required("Field is required")
            : Yup.string(),
      });
    },
    onSubmit: async (values, { resetForm, setFieldValue }) => {

      const updated_messages_array=addMessageToJson()
      const newMessages=JSON.stringify(updated_messages_array);
      const formattedDateTime = moment().format();
      const newValues = {
        fromPharmacyId: currentUser.user.pharmacyBranchId, //values.FromPharmacyId,
        fromPharmacyName: currentUser.user.pharmacyName,
        fromPharmacyNCPDP: currentUser.user.pharmacyId, //
        toPharmacyId: toPharmacy.id,
        toPharmacyNCPDP: toPharmacy.ncpdp,
        toPharmacyName: toPharmacy.label,
        patientId: patient?.id,
        patientName: patient?.label,
        prescribedDrugId: prescribedDrug?.id,
        prescribedDrugName: prescribedDrug?.label,
        messageText: other,
        requestType: selectedMessageRequestType,
        createdDate: formattedDateTime,
        isRead: 0,
        messages:newMessages,
      };
      const result = await MessageAnotherPharmacyService.Create(newValues);
      if (result.error === null) {
        clearForm();
        setToPharmacy(null);
        props.onHide(result?.data);
      }
    },
  });

  return (
    <>
      <Modal
        onHide={() => {
          props.onHide();
        }}
        show={props.show}
        aria-labelledby="message-another-pharmacy-modal-title"
        centered
        backdrop="static"
        keyboard={false}
        scrollable
        fullscreen="lg-down"
        dialogClassName={!showMoreFields ? "modal-lg" : "modal-xxl"}
      >
        <Modal.Header closeButton className="">
          <Modal.Title id="message-another-pharmacy-modal-title">
            Message Another Pharmacy
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="App2"></div>
          <form onSubmit={formik.handleSubmit}>
            <div className="container">
              <div className={getCustomClasses("toPharmacyId")}>
                <label htmlFor="pharmacy-ddl" className="form-label">
                  Pharmacy
                </label>
                {fieldsSchema["toPharmacyId"].isRequired ? (
                  <i className="fa fa-asterisk"></i>
                ) : null}

                <AsyncSelect
                  test-id="pharmacy-ddl"
                  id="pharmacy-ddl"
                  className="me-3 w-100"
                  classNamePrefix="react-select"
                  menuPosition="fixed"
                  getOptionValue={(option) => option.id}
                  isClearable={true}
                  value={toPharmacy}
                  onBlur={formik.handleBlur}
                  loadOptions={(inputValue, callback) =>
                    loadOptions("pharmacies", inputValue, callback)
                  }
                  onChange={(item) => {
                      formik.setFieldValue(
                        "toPharmacyId", item?.ncpdp || ""
                        );
                      setToPharmacy(item);
                  }}
                  formatOptionLabel={formatOptionLabel}
                  placeholder="Select Pharmacy"
                />

                {formik.touched.toPharmacyId && formik.errors.toPharmacyId && (
                  <small className="text-danger">
                    {formik.errors.toPharmacyId}
                  </small>
                )}
              </div>

              <div>
                <div>
                  <label className="form-label">Request Type</label>

                  <Accordion onSelect={handleSelect_section}>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Reverse Claim</Accordion.Header>
                      <Accordion.Body>
                        <div className={getCustomClasses("patient")}>
                          <label htmlFor="patient-ddl" className="form-label">
                            Patient
                          </label>
                          {fieldsSchema["patient"].isRequired ? (
                            <i className="fa fa-asterisk"></i>
                          ) : null}
                          <div className="d-flex">
                            <AsyncSelect
                              id="patient-ddl"
                              test-id="patient-ddl"
                      
                              className="me-3 w-100"
                              classNamePrefix="react-select"
                              menuPosition="fixed"
                              components={{ Menu: patientMenu, SingleValue }}
                              getOptionValue={(option) => option.id}
                              isClearable={true}
                              value={patient}
                              
                              onBlur={formik.handleBlur}
                              loadOptions={(inputValue, callback) =>
                                loadOptions("patients", inputValue, callback)
                              }
                              formatOptionLabel={(dataRow, { inputValue }) => {
                                return (
                                  <div className="row">
                                    <div className="col">{dataRow.label}</div>
                                    <div className="col">{dataRow.mobile}</div>
                                    <div className="col">
                                      {dataRow.dateOfBirth}
                                    </div>
                                  </div>
                                );
                              }}
                              onChange={(item) => {
                                setPatient(item ||"");
                                formik.setFieldValue(
                                  "PatientName", item?.label || ""
                                );
                                formik.setFieldValue(
                                  "patientid",item?.id || ""
                                );
                              }}
                              placeholder="Select Patient"
                            />

                            <button
                              className="btn btn-outline-primary rounded-circle"
                              type="button"
                              title="Add New Patient"
                              test-id="add-new-patient"
                              onClick={() => setPatientModalShow(true)}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </button>
                          </div>
                          {formik.touched.patientid &&
                            formik.errors.patientid && (
                              <small className="text-danger">
                                {formik.errors.patientid}
                              </small>
                            )}
                        </div>
                        <div className={getCustomClasses("prescribedDrugId")}>
                          <label
                            htmlFor="prescribed-drug-ddl"
                            className="form-label"
                          >
                            Prescribed Drug
                          </label>
                          {fieldsSchema["prescribedDrugId"].isRequired ? (
                            <i className="fa fa-asterisk"></i>
                          ) : null}
                          <AsyncCreatableSelect
                            onInputChange={(item) => {
                            }}
                            id="prescribed-drug-ddl"
                            test-id="prescribed-drug-ddl"
                            className="me-3 w-100"
                            classNamePrefix="react-select"
                            menuPosition="fixed"
                            placeholder="Select Prescribed Drug"
                            getOptionValue={(option) => option.id}
                            isClearable={true}
                            value={prescribedDrug}
                            onBlur={formik.handleBlur}
                            onCreateOption={(inputValue) => {
                              setPrescribedDrug({
                                id: null,
                                label: inputValue,
                              });
                            }}
                            onChange={(item) => {
                              setPrescribedDrug(item);
                              formik.setFieldValue(
                                "prescribedDrugId", item?.id || ""
                              );
                             
                            }}
                            loadOptions={(inputValue, callback) =>
                              loadOptions("drugs", inputValue, callback)
                            }
                            formatOptionLabel={formatOptionLabel}
                          />

                          {formik.touched.prescribedDrugId &&
                            formik.errors.prescribedDrugId && (
                              <small className="text-danger">
                                {formik.errors.prescribedDrugId}
                              </small>
                            )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Check If Medicine In Stock
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className={getCustomClasses("prescribedDrugId")}>
                          <label
                            htmlFor="prescribed-drug-ddl"
                            className="form-label"
                          >
                            Prescribed Drug
                          </label>
                          {fieldsSchema["prescribedDrugId_stock"].isRequired ? (
                            <i className="fa fa-asterisk"></i>
                          ) : null}
                          <AsyncCreatableSelect
                            onInputChange={(item) => {
                            }}
                            id="prescribed-drug-ddl"
                            test-id="prescribed-drug-ddl"
                            className="me-3 w-100"
                            classNamePrefix="react-select"
                            menuPosition="fixed"
                            placeholder="Select Prescribed Drug"
                            getOptionValue={(option) => option.id}
                            isClearable={true}
                            value={prescribedDrug}
                            onBlur={formik.handleBlur}
                            onCreateOption={(inputValue) => {
                              setPrescribedDrug({
                                id: null,
                                label: inputValue,
                              });
                            }}
                            onChange={(item) => {
                              
                              setPrescribedDrug(item);
                              formik.setFieldValue(
                                "prescribedDrugId_stock", item?.id || ""
                              );
                            }}
                            loadOptions={(inputValue, callback) =>
                              loadOptions("drugs", inputValue, callback)
                            }
                            formatOptionLabel={formatOptionLabel}
                          />

                          {formik.touched.prescribedDrugId_stock &&
                            formik.errors.prescribedDrugId_stock && (
                              <small className="text-danger">
                                {formik.errors.prescribedDrugId_stock}
                              </small>
                            )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey="3"
                      style={
                        isItemSelected("3") ? { backgroundColor: "page" } : {}
                      }
                    >
                      <Accordion.Header>Other</Accordion.Header>
                      <Accordion.Body>
                        <div className={getCustomClasses("other")}>
                          <Form.Label htmlFor="other">Other</Form.Label>
                          {fieldsSchema["other"].isRequired ? (
                            <i className="fa fa-asterisk"></i>
                          ) : null}
                          <Form.Control
                            id="other-input"
                            name="other"
                            as="textarea"
                            value={other}
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              setOther(e.target.value);
                              formik.setFieldValue(
                                "other",
                                e.target.value ? e.target.value : ""
                              );
                            }}
                            placeholder="Enter Other"
                            style={{ height: "100px" }}
                          />
                          <small className="text-danger">
                            {formik.errors.other}
                          </small>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-default"
            test-id="message-another-pharmacy-submit-btn"
            onClick={formik.submitForm}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <EditPatientModal
        show={patientModalShow}
        onHide={(patient) => {
          if (patient !== undefined) {
            setPatient({
              id: patient?.Id,
              label: patient
                ? `${patient?.FirstName} ${patient?.LastName}`
                : "",
            });
          }
          setPatientModalShow(false);
        }}
      ></EditPatientModal>
    </>
  );
};

export default MessageAnotherPharmacyModal;
